<template>
  <div>
    <!-- 검색창 -->
    <div class="searchArea w50">
      <span style="font-weight: bold;font-size: var(--font16);"
        >{{$t('SECTION LIST')}}</span
      >
    </div>
    <div class="col-12 d-flex">
      <div class="col-6">
        <v-data-table
          v-model="selected"
          :headers="lbsShelfHeaders"
          :items="lbsShelfItems"
          item-key="sno"
          :hide-default-footer="true"
          single-select
          height="500"
          fixed-header
          :options.sync="options"
          class="tbl-type01 flat-type02 style03 grayline type3"
          @click:row="rowSelect"
          :sort-by.sync="sortBy"
          disable-pagination
        >
          <template slot="no-data">
            <p>
              {{ $t("No data available") }}
            </p>
          </template>
        </v-data-table>
        <div class="table-options">
          <div>
            <v-btn text class="btn" @click="addBtn()" :disabled="btnDisabled || addBtnDisabled">{{
              $t("Add")
            }}</v-btn>
            <v-btn
              text
              class="btn ml-2"
              :disabled="editBtnDisabled || btnDisabled || addBtnDisabled "
              @click="editBtn()"
              >{{ $t("Edit") }}
            </v-btn>
            <v-btn
              text
              @click="deleteAddSection()"
              :disabled="deleteBtnDisabled || btnDisabled || addBtnDisabled"
              class="btn ml-2"
              >{{ $t("Delete") }}
            </v-btn>
          </div>
        </div>
      </div>
      <div class="col-6">
        <v-data-table
          v-model="selectedShelf"
          :headers="lbsShelfHeaderName"
          :items="lbsShelfItemsCoordinate"
          :hide-default-footer="true"
          item-key="sno"
          :options.sync="optionsShelf"
          height="500px"
          fixed-header
          single-select
          disable-pagination
          class="tbl-type01 flat-type02 style03 grayline type3"
          @click:row="rowSelectShelf"
          :sort-by.sync="sortByShelf"
        >
          <template v-slot:[`item.coordinateX`]="{ item }">
            X:{{ item.coordinateX }} Y:{{ item.coordinateY }}
          </template>
          <template v-slot:[`item.positionId`]="{ item }">
            {{ item.id.positionId }}
          </template>
          <template slot="no-data">
            <p>
              {{ $t("No data available") }}
            </p>
          </template>
        </v-data-table>
        <div class="table-options">
          <div>
            <v-btn
              text
              class="btn"
              :disabled="addShelfDisabled || btnDisabled || addBtnDisabled"
              @click="addShelfBtn()"
              >{{ $t("Add") }}</v-btn
            >
            <v-btn
              text
              class="btn ml-2"
              :disabled="editShelfDisabled || btnDisabled || addBtnDisabled"
              @click="editShelfBtn()"
              >{{ $t("Edit") }}
            </v-btn>
            <v-btn
              text
              class="btn ml-2"
              :disabled="deleteShelfDisabled || btnDisabled || addBtnDisabled"
              @click="deleteAddShelf()"
              >{{ $t("Delete") }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <!-- ADD popup -->
    <v-dialog v-model="newAddVisible" persistent scrollable width="450">
      <v-card class="popup add_store_popup">
        <v-card-title>
          <h3 class="page-title-bar">
            <i class="ico ico-multi"></i>{{ $t("ADD NEW SECTION") }}
          </h3>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="InputFloor"
            outlined
            dense
            hide-details
            :placeholder="`${$t('Input Floor')}`"
            class="form-input mb-2"
            :label="$t('Floor')"
          >
          </v-text-field>

          <v-text-field
            v-model="sectionName"
            :placeholder="`${$t('Input Section Name')}`"
            :label="$t('Section Name')"
            class="form-input"
            outlined
            dense
            hide-details
          >
          </v-text-field>
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          <v-btn
            :disabled="InputFloor === '' || sectionName === ''"
            @click="SaveClick"
            text
            icon
            class="btn"
            >{{ $t("Save") }}
          </v-btn>
          <v-btn @click="cancelAddSection" class="btn" text icon
            >{{ $t("Cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDIT popup -->
    <v-dialog v-model="editVisible" width="450" persistent scrollable>
      <v-card class="popup add_store_popup">
        <v-card-title>
          <h3 class="page-title-bar">
            <i class="ico ico-multi"></i>{{ $t("EDIT SECTION") }}
          </h3>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="InputEditFloor"
            outlined
            dense
            hide-details
            :placeholder="`${$t('Input Floor')}`"
            class="form-input mb-2"
            clearable
            :label="$t('Floor')"
            disabled
          >
          </v-text-field>

          <v-text-field
            v-model="editSectionName"
            :placeholder="`${$t('Input Section Name')}`"
            :label="$t('Section Name')"
            class="form-input"
            outlined
            dense
            hide-details
            clearable
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="editSaveClick" text icon class="btn"
            >{{ $t("Save") }}
          </v-btn>
          <v-btn @click="cancelEditSection" class="btn" text icon
            >{{ $t("Cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ADD Shelf popup -->
    <v-dialog v-model="newAddShelfVisible" persistent scrollable width="450">
      <v-card class="popup add_store_popup">
        <v-card-title>
          <h3 class="page-title-bar">
            <i class="ico ico-multi"></i>{{ $t("ADD NEW SHELF") }}
          </h3>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="InputShelfFloor"
            outlined
            dense
            hide-details
            :placeholder="`${$t('Input Shelf Name')}`"
            class="form-input mb-2"
            :label="$t('Shelf Name')"
          >
          </v-text-field>
          <div style="font-size: var(--font14); opacity: 1">
            {{$t('Shelf Coordinate')}}
          </div>
          <div class="d-flex mt-2">
            <div class="d-flex" style="align-items: center; margin-right: 13px">
              <span class="mr-2">X:</span>
              <v-text-field
                v-model="shelfSectionNameX"
                :placeholder="`${$t('Input Point X')}`"
                class="form-input pt-0"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </div>
            <div class="d-flex" style="align-items: center">
              <span class="mr-2"> Y:</span>
              <v-text-field
                v-model="shelfSectionNameY"
                :placeholder="`${$t('Input Point Y')}`"
                class="form-input pt-0"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            :disabled="
              InputShelfFloor === '' ||
                shelfSectionNameX === '' ||
                shelfSectionNameY === ''
            "
            @click="addSaveShelf()"
            text
            icon
            class="btn"
            >{{ $t("Save") }}
          </v-btn>
          <v-btn @click="cancelAddShelf()" class="btn" text icon
            >{{ $t("Cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDIT Shelf popup -->
    <v-dialog
      v-model="editShelfVisible"
      persistent
      scrollable
      width="450"
      v-if="editShelfVisible"
    >
      <v-card class="popup add_store_popup">
        <v-card-title>
          <h3 class="page-title-bar">
            <i class="ico ico-multi"></i>{{ $t("EDIT SHELF") }}
          </h3>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="InputEditShelfFloor"
            outlined
            dense
            hide-details
            :placeholder="`${$t('Input Floor')}`"
            class="form-input mb-2"
            clearable
            :label="$t('Floor')"
            disabled
          >
          </v-text-field>
          <v-text-field
            v-model="shelfsectionName"
            outlined
            dense
            hide-details
            :placeholder="`${$t('Input Floor')}`"
            class="form-input mb-2"
            clearable
            :label="$t('Section Name')"
            disabled
          >
          </v-text-field>
          <v-text-field
            v-model="InputShelfName"
            outlined
            dense
            hide-details
            :placeholder="`${$t('Input Floor')}`"
            class="form-input mb-2"
            :label="$t('Shelf Name')"
            required
          >
          </v-text-field>
          <div style="font-size: var(--font14); opacity: 1">
            {{$t('Shelf Coordinate')}}
          </div>
          <div class="d-flex mt-2">
            <div class="d-flex" style="align-items: center; margin-right: 13px">
              <span class="mr-2">X:</span>
              <v-text-field
                v-model="shelfSectionX"
                :placeholder="`${$t('Input Point X')}`"
                class="form-input pt-0"
                outlined
                dense
                hide-details
                required
              >
              </v-text-field>
            </div>
            <div class="d-flex" style="align-items: center">
              <span class="mr-2"> Y:</span>
              <v-text-field
                v-model="shelfSectionY"
                :placeholder="`${$t('Input Point Y')}`"
                class="form-input pt-0"
                outlined
                dense
                hide-details
                required
              >
              </v-text-field>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            :disabled="
              InputShelfName === '' ||
                shelfSectionX === '' ||
                shelfSectionY === ''
            "
            @click="editSaveShelf"
            text
            icon
            class="btn"
            >{{ $t("Save") }}
          </v-btn>
          <v-btn @click="cancelEditShelf" class="btn" text icon
            >{{ $t("Cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import EventBus from '@/plugins/eventBus'
// import codes from '@/plugins/codes'
import configs from '@/plugins/configs'
import commons from '@/plugins/commons'
import axios from 'axios'
import Cookies from 'js-cookie'
import codes from '@/plugins/codes'

const requests = {
  postAddShelf: {
    method: 'post',
    url: '/lbs/common/lbs/section/'
  },
  postAddShelfCoordinate: {
    method: 'post',
    url: '/lbs/common/lbs/coordinate/'
  },
  getLBSshelf: {
    method: 'get',
    url: '/lbs/common/lbs/section/'
  },
  getLBSshelfCordinate: {
    method: 'get',
    url: '/lbs/common/lbs/coordinate/'
  },
  editSection: {
    method: 'put',
    url: '/lbs/common/lbs/section/'
  },
  editShelfCoordinate: {
    method: 'put',
    url: '/lbs/common/lbs/coordinate/'

  },
  deleteAddSection: {
    method: 'delete',
    url: '/lbs/common/lbs/section/'
  },
  deleteAddShelf: {
    method: 'delete',
    url: '/lbs/common/lbs/coordinate/'
  }
}
export default {
  components: {},
  data () {
    return {
      selectedRow: [],
      selectedShelfRow: [],
      // buttons
      addShelfenable: false,
      editbuttonenable: false,
      deletbuttonenable: false,
      addShelfCoordinateenable: false,
      editbuttonCoordinatenable: false,
      deletbuttonCoordinatenable: false,
      // addpopup
      dialog: false,
      newAddVisible: false,
      newAddShelfVisible: false,
      editShelfVisible: false,
      InputFloor: '',
      sectionName: '',
      InputEditFloor: '',
      editSectionName: '',
      editShelfName: '',
      InputShelfFloor: '',
      shelfSectionNameX: '',
      shelfSectionNameY: '',
      shelfSectionX: '',
      shelfSectionY: '',
      lbsShelfItemsCoordinate: [],
      lbsShelfItems: [],
      sectionNameee: '',
      deleteBtnDisabled: true,
      editBtnDisabled: true,
      addShelfDisabled: true,
      editShelfDisabled: true,
      deleteShelfDisabled: true,
      shelfsectionName: '',
      InputEditShelfFloor: '',
      InputShelfName: '',
      lbsShelfHeaderName: [
        { text: '#', align: 'center', value: 'sno', sortable: false },
        { text: this.$t('SHELF NAME'), value: 'positionId' },
        { text: this.$t('SHELF COORDINATE'), value: 'coordinateX' }
      ],

      lbsShelfHeaders: [
        { text: '#', align: 'center', value: 'sno', sortable: false },
        { text: this.$t('FLOOR'), value: 'floor', sortable: true },
        { text: this.$t('SECTION NAME'), value: 'section', sortable: true }
      ],

      // editpopup
      editVisible: false,
      //
      lbsEnabled: true,
      options: {},
      optionsShelf: {},
      selected: [],
      selectedShelf: [],
      sectionlistdata: '',
      btnDisabled: null,
      sortBy: 'floor',
      sortByShelf: 'positionId',
      sortOrderValue: '',
      sortKeyvalue: ''
    }
  },
  computed: {

    itemsWithSno () {
      return this.lbsShelfItems.map((d, index) => ({ ...d, sno: index + 1 }))
    },
    itemsWithSno1 () {
      return this.lbsShelfItemsCoordinate.map((d, index) => ({ ...d, sno: index + 1 }))
    },

    ...mapGetters({ store: 'dataStore/GET_SELECTED_STORE' }),

    addBtnDisabled () {
      return (!this.store.code || !this.lbsEnabled)
    }

  },
  watch: {
    options: {
      handler () {
        // 선택된 스토가 있으면 실행
        const selectedStoreCode = this.$store.state.dataStore.selectedStore.code
        if (selectedStoreCode) {
          this.getLBSshelf()
        }
      },
      deep: true
    },
    optionsShelf: {
      handler () {
        // 선택된 스토가 있으면 실행
        if (this.sectionlistdata !== '') {
          this.getLBSshelfCordinate(this.sectionlistdata)
        }
      },
      deep: true
    },
    store: {
      handler (store) {
        this.getLBSshelf()
        this.setLbsEnabled(store.code)
      }
    }
  },
  mounted () {
    this.$store.dispatch('auth/getDisabledBtn', '6600').then(flag => {
      this.btnDisabled = flag
    })
    EventBus.$emit('enableSelectedStores', true)
    const selectedStoreCode = this.$store.state.dataStore.selectedStore.code
    if (!selectedStoreCode) {
      EventBus.$emit('openSelectedStores')
    }

    if (!this.store.code) {
      EventBus.$emit('openSelectedStores')
    }
    if (selectedStoreCode) this.setLbsEnabled(selectedStoreCode)
  },
  methods: {
    cancelAddSectionDialog () {
      this.newAddVisible = !this.newAddVisible
    },
    cancelEditSectionDialog () {
      this.editVisible = !this.editVisible
    },
    cancelAddSection () {
      this.InputFloor = ''
      this.sectionName = ''
      this.newAddVisible = !this.newAddVisible
    },
    cancelEditSection () {
      this.editVisible = !this.editVisible
    },
    cancelAddShelfDialog () {
      this.newAddShelfVisible = !this.newAddShelfVisible
    },
    cancelAddShelf () {
      this.InputShelfFloor = ''
      this.shelfSectionNameX = ''
      this.shelfSectionNameY = ''
      this.newAddShelfVisible = !this.newAddShelfVisible
    },
    cancelEditShelf () {
      this.editShelfVisible = !this.editShelfVisible
    },
    cancelEditShelfDialog () {
      this.editShelfVisible = !this.editShelfVisible
    },
    // popup btn
    addBtn () {
      console.log('bhgghjgjhgbhj')
      this.newAddVisible = true
    },

    editBtn () {
      this.editVisible = true
      this.InputEditFloor = this.selectedRow.floor
      this.editSectionName = this.selectedRow.section
    },
    addShelfBtn () {
      this.newAddShelfVisible = true
    },
    editShelfBtn () {
      console.log(this.selectedShelfRow)
      this.editShelfVisible = true
      this.InputEditShelfFloor = this.selectedRow.floor
      this.shelfsectionName = this.selectedRow.section
      this.InputShelfName = this.selectedShelfRow.id.positionId
      this.shelfSectionX = this.selectedShelfRow.coordinateX
      this.shelfSectionY = this.selectedShelfRow.coordinateY
    },
    // save
    SaveClick () {
      this.postAddShelf()
    },
    editSaveClick () {
      this.editSection()
    },
    addSaveShelf () {
      this.postAddShelfCoordinate()
    },
    editSaveShelf () {
      this.editShelfCoordinate()
    },
    // row select
    rowSelect (data, row) {
      this.selectedRow = data
      row.select(true)
      this.sectionlistdata = ''
      this.sectionlistdata = data
      this.getLBSshelfCordinate(data)
      this.deleteBtnDisabled = false
      this.editBtnDisabled = false
      this.addShelfDisabled = false
    },
    // row select 2nd table
    rowSelectShelf (item, val) {
      this.selectedShelfRow = item
      val.select(true)
      this.editShelfDisabled = false
      this.deleteShelfDisabled = false
    },
    // Add section API
    postAddShelf () {
      console.log('save')
      const addShelfdata = {
        floor: this.InputFloor,
        section: this.sectionName
      }
      const params = {}
      const config = { params: params }
      const url = configs.ServerAddress + requests.postAddShelf.url

      return this.$utils
        .callAxiosWithBody(
          requests.postAddShelf.method,
          `${url}${this.store.code}`,
          addShelfdata,
          config
        )
        .then((res) => {
          const result = res.data
          this.getLBSshelf()
          this.newAddVisible = false
          EventBus.$emit('messageAlert', this.$t(result.returnMsg))
          this.$emit('fireResetAnchors')
          this.InputFloor = ''
          this.sectionName = ''
        })
        .catch((error) => {
          console.log(error.response.status)
          if (error.response.status === 500) {
            EventBus.$emit('messageAlert', this.$t('Something went wrong'))
          } else {
            EventBus.$emit(
              'messageAlert',
              this.$t(error.response.data.returnMsg)
            )
          }
          console.log(error)
          this.InputFloor = ''
          this.sectionName = ''
        })
    },
    // Add Shelf API
    postAddShelfCoordinate () {
      const addShelfdata = {
        floor: this.selectedRow.floor,
        section: this.selectedRow.section,
        positionId: this.InputShelfFloor,
        coordinateX: this.shelfSectionNameX,
        coordinateY: this.shelfSectionNameY
      }
      const params = { }
      const config = { params: params }
      const url = configs.ServerAddress + requests.postAddShelfCoordinate.url

      return this.$utils
        .callAxiosWithBody(
          requests.postAddShelfCoordinate.method,
          `${url}${this.store.code}`,
          addShelfdata,
          config
        )
        .then((res) => {
          console.log('resssssssss', res)
          const result = res.data
          const data = { floor: this.selectedRow.floor, section: this.selectedRow.section }
          console.log(data)
          this.getLBSshelfCordinate(data)
          this.newAddShelfVisible = false
          EventBus.$emit('messageAlert', this.$t(result.returnMsg))
          this.InputShelfFloor = ''
          this.shelfSectionNameX = ''
          this.shelfSectionNameY = ''
        })
        .catch((error) => {
        //   if (error.response.status === 500) {
        //     EventBus.$emit('messageAlert', this.$t('Something went wrong'))
        //   } else {
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.returnMsg)
          )
          //   }
          console.log(error)
          this.InputShelfFloor = ''
          this.shelfSectionNameX = ''
          this.shelfSectionNameY = ''
        })
    },
    // Edit Section
    editSection () {
      console.log('edittttttttttttt')
      const params = { floor: this.InputEditFloor, oldSection: this.selectedRow.section, newSection: this.editSectionName }
      const config = { params: params }
      var reqBody = {}
      const url = configs.ServerAddress + requests.editSection.url
      console.log(requests.editSection.method,
          `${url}${this.store.code}`, config
      )
      this.$utils
        .callAxiosWithBody(
          requests.editSection.method,
          `${url}${this.store.code}`, reqBody,
          config
        )
        .then((res) => {
          const result = res.data
          EventBus.$emit('messageAlert', this.$t(result.returnMsg))
          this.getLBSshelf()
          this.editVisible = false
          this.selectedRow = []
        })
        .catch((error) => {
          console.log(error.response.status)
          if (error.response.status === 500) {
            EventBus.$emit('messageAlert', this.$t('Something went wrong'))
          } else {
            EventBus.$emit(
              'messageAlert',
              this.$t(error.response.data.returnMsg)
            )
          }
          console.log(error)
        })
    },
    editShelfCoordinate () {
      console.log(this.InputShelfName)
      const params = {
        floor: this.selectedRow.floor,
        section: this.selectedRow.section,
        positionId: this.selectedShelfRow.id.positionId,
        coordinateX: this.shelfSectionX,
        coordinateY: this.shelfSectionY,
        oldShelf: this.selectedShelfRow.id.positionId,
        newShelf: this.InputShelfName
      }
      const config = { params: params }
      var reqBody = {}
      const url = configs.ServerAddress + requests.editShelfCoordinate.url

      return this.$utils
        .callAxiosWithBody(
          requests.editShelfCoordinate.method,
          `${url}${this.store.code}`, reqBody,
          config
        )
        .then((res) => {
          console.log('resssssssss', res)
          const result = res.data
          const data = { floor: this.selectedRow.floor, section: this.selectedRow.section }
          this.getLBSshelfCordinate(data)
          this.editShelfVisible = false
          EventBus.$emit('messageAlert', this.$t(result.returnMsg))
        })
        .catch((error) => {
          if (error) {
            if (error.response.status === 500) {
              EventBus.$emit('messageAlert', this.$t('Something went wrong'))
            } else {
              EventBus.$emit(
                'messageAlert',
                this.$t(error.response.data.returnMsg)
              )
            }
          }

          console.log(error)
        })
    },
    // section data Table

    buildParmas () {
      const { sortBy, sortDesc } = this.options
      console.log(this.options)
      const params = {}
      let sort = null
      if (commons.isSorting(sortBy, sortDesc)) {
        const sortKey = this.getsectionListSortKey(sortBy[0])
        const sortOrder = sortDesc[0]
        sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
      }
      if (sort !== null) params.sort = sort
      console.log(params)

      return params
    },
    getsectionListSortKey (sortKey) {
      switch (sortKey) {
        case 'Floor': sortKey = 'floor'
          break
        case 'Section Name': sortKey = 'section'
          break
        default: break
      }
      return sortKey
    },
    getLBSshelf () {
      this.lbsShelfItems = []
      const params = this.buildParmas()
      const config = { params }
      const url = configs.ServerAddress + requests.getLBSshelf.url
      this.$utils
        .callAxios(
          requests.getLBSshelf.method,
          `${url}${this.store.code}`,
          config
        )
        .then((res) => {
          console.log('res', res)
          res.data.forEach((item, index) => {
            item.sno = index + 1
            this.lbsShelfItems.push(item)
          })
          this.lbsShelfItems = [...new Set(this.lbsShelfItems)]
        })
        .catch((error) => {
          console.log(error)
          // EventBus.$emit(
          //   'messageAlert',
          //   this.$t(error.response.data.responseMessage)
          // )
        })
    },
    // shelf data Table
    buildParmasshelf () {
      const { sortBy, sortDesc } = this.optionsShelf
      const params = {}
      let sort = null
      if (commons.isSorting(sortBy, sortDesc)) {
        const sortKey = this.getshelfListSortKey(sortBy[0])
        this.sortKeyvalue = sortKey
        const sortOrder = sortDesc[0]
        this.sortOrderValue = sortOrder ? 'desc' : 'asc'
        sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
      }
      if (sort !== null) params.sort = sort

      return params
    },
    getshelfListSortKey (sortKey) {
      switch (sortKey) {
        case 'Shelf Name': sortKey = 'positionId'
          break
        case 'Shelf Coordinate': sortKey = 'coordinateX'
          break
        default: break
      }
      return sortKey
    },
    async getLBSshelfCordinate (item) {
      this.lbsShelfItemsCoordinate = []
      console.log('sssssssssss', this.sortKeyvalue)
      var params = this.buildParmasshelf()
      params.floor = item.floor
      params.section = item.section

      var Newparams = new URLSearchParams()
      if (this.sortKeyvalue === 'coordinateX') {
        var coordinateY = 'coordinateY' + ',' + this.sortOrderValue
        Newparams.append('floor', params.floor)
        Newparams.append('section', params.section)
        if (params.sort) {
          Newparams.append('sort', params.sort)
          Newparams.append('sort', coordinateY)
        }
      } else {
        Newparams.append('floor', params.floor)
        Newparams.append('section', params.section)
        if (params.sort) {
          Newparams.append('sort', params.sort)
        }
      }
      params = Newparams
      var config = { params }
      const headers = await this.getRequestHeader()
      console.log(headers)
      config.headers = headers
      const url = configs.ServerAddress + requests.getLBSshelfCordinate.url
      console.log('urllllllllll', url)
      axios.get(`${url}${this.store.code}`, config)
      // this.$utils
      //   .callAxios(
      //     requests.getLBSshelfCordinate.method,
      //     `${url}${this.store.code}`,
      //     config
      //   )
        .then((res) => {
          if (res.status === 200) {
            this.lbsShelfItemsCoordinate = []
            res.data.forEach((item, index) => {
              item.sno = index + 1
              item.positionId = item.id.positionId
              this.lbsShelfItemsCoordinate.push(item)
            })
            this.lbsShelfItemsCoordinate = [...new Set(this.lbsShelfItemsCoordinate)]
            console.log(this.lbsShelfItemsCoordinate)
          }
          // else {
          //   EventBus.$emit('messageAlert', this.$t('No data available'))
          // }
          console.log('responsesssssss', res)
        })
        .catch((error) => {
          console.log(error)
          EventBus.$emit('messageAlert', this.$t('Something went wrong'))
        })
    },
    // delete Section
    deleteAddSection () {
      console.log('ssssssssss')
      const deleteBody = { floor: this.selectedRow.floor, section: this.selectedRow.section }
      const params = { }
      const config = {
        data: deleteBody,
        params: params
      }
      const url = configs.ServerAddress + requests.deleteAddSection.url
      this.$utils
        .callAxios(
          requests.deleteAddSection.method,
          `${url}${this.store.code}`,
          config
        )
        .then((res) => {
          const result = res.data
          console.log(result)
          if (result.returnCode === '200 OK') {
            EventBus.$emit('messageAlert', this.$t(result.returnMsg))
            this.getLBSshelf()
          }
          this.selectedRow = []
        })
        .catch((error) => {
          if (error.response !== undefined) {
            EventBus.$emit(
              'messageAlert',
              this.$t(error.response.data.returnMsg)
            )
          } else {
            EventBus.$emit(
              'messageAlert',
              this.$t('Failed to delete')
            )
          }
          console.log('Failed to delete')
        })
    },
    // delete Shelf
    deleteAddShelf () {
      const data = { floor: this.selectedRow.floor, section: this.selectedRow.section, positionId: this.selectedShelfRow.id.positionId }
      const params = { }
      const config = {
        data: data,
        params: params
      }
      const url = configs.ServerAddress + requests.deleteAddShelf.url
      this.$utils
        .callAxios(
          requests.deleteAddShelf.method,
          `${url}${this.store.code}`,
          config
        )
        .then((res) => {
          const result = res.data
          EventBus.$emit('messageAlert', this.$t(result.returnMsg))
          this.getLBSshelfCordinate(this.sectionlistdata)
          this.selectedShelfRow = []
        })
        .catch((error) => {
          console.log(error)
          EventBus.$emit('messageAlert', this.$t('something went wrong'))
        })
    },
    async getRequestHeader () {
      const vuex = await JSON.parse(localStorage.getItem('vuex'))
      // const user = vuex.auth.user
      // for cookies
      const session = Cookies.get('sessionId')

      const loginState = vuex.auth.loginState
      const hearder = {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8;'
      }
      const url = configs.ApiUrl
      // https 요청인 경우 API key 추가.
      if (url && url.toLowerCase().includes('https://')) {
        hearder['api-key'] = configs.ApiKey
      }
      // login 상태라면 sessionId 추가.
      if (loginState === true) hearder.sessionId = session
      // Cloud 버전이라면 Token추가.
      if (process.env.VUE_APP_PRODUCTION_TYPE === 'cloud') {
        hearder.Authorization = `Bearer ${vuex.auth.tokenAPI.accessToken}`
      }
      if (configs.keycloakLogin === true) {
        hearder.Authorization = `Bearer ${vuex.auth.tokenAPI}`
      }
      return hearder
    },
    getStoreInfo (storeCode) {
      const params = { store: storeCode }
      const config = { params }
      if (this.productionType === 'cloud') params.company = this.$store.state.auth.user.company
      return this.$utils
        .callAxios(
          codes.requests.getStoreSummary.method,
          codes.requests.getStoreSummary.url,
          config
        )
        .then(res => {
          return res.data
        })
    },
    async setLbsEnabled (storeCode) {
      const storeInfo = await this.getStoreInfo(storeCode)
      this.lbsEnabled = (storeInfo.lbsEnabled === true)
      this.deletbuttonenable = storeInfo.lbsEnabled
    }
  }
}
</script>

<style scoped>
.pageAlign {
  float: right;
}
::v-deep .v-btn.btn {
  width: 120px !important;
}
::v-deep tr:hover {
  cursor: pointer;
}
::v-deep .selected_Row {
  background: #09263f !important;
  color: #fff !important;
}
::v-deep .selected_Row td {
  color: #ffffff !important;
}
::v-deep .tbl-type01 tbody tr.v-data-table__selected td {
  color: #ffffff !important;
    border-bottom: 1px solid #f3f3f5;
    font-size: var(--font14) !important;
    background: #09263f !important;
}
.tableclass{
  height: 527px;
    overflow-y: auto;
}
::v-deep .theme--light.v-data-table.v-data-table--fixed-header thead th{
  background: #ececec !important;
}
/* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }

</style>
