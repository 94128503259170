<template>
  <div>
    <h2 class="page-title-bar">
      <i class="ico ico-multi"></i>{{ $t('LBS Shelf') }}
    </h2>
    <LBSShelf />
  </div>
</template>

<script>

import LBSShelf from './lbs/LBSShelf'

export default {
  name: 'LBS_Shelf',
  components: {
    LBSShelf
  },
  data () {
    return {}
  },
  mounted () {
  },
  methods: {

  }
}
</script>
